import React from "react";
import {Col, Row} from "react-bootstrap";
import Pets from "./Pets";

const DiscountPets = () => {

    return (
        <>
            <Row>
                <Col>
                    <p>Desde 5% dependiendo del servicio y consulta veterinaria con costo preferencial</p>
                    <Pets />
                </Col>
            </Row>
        </>
    )
};

export default DiscountPets;
