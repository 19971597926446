import {Card} from 'react-bootstrap'
import React from "react";
import moment from "moment";

function CardMembresias(props){

    return (

        <Card className="shadow-sm card-mis-membresias mb-3">

            <div className="rightCircle" onClick={e=>props?.onClickCardDelete(props?.item)}>
                <i className="icomoon font-20 icon-F__cerrar color-red"></i>
            </div>

            <Card.Body onClick={e=>props?.selectMembresia(props?.item)}>
                <h6 className="card-title text-primary">{props?.item?.account?.name}</h6>
                <div className="d-flex align-items-center">
                    <div className="pe-4">
                          <span
                              className="bg-blurey text-white d-flex justify-content-center align-items-center wh-40 m-auto rounded-circle">
                                <i className={`icomoon font-30 ${props?.item?.account?.clcuentaIke !== 2591 ? 'icon-F__mano blue-hand' : 'icon-F__Patita pet-foot'}`}></i>
                          </span>
                    </div>
                    <div>
                        <h6 className="card-title color-bluerey">{props?.item?.name} {props?.item?.lastName}</h6>
                        <label className="card-title text-secondary d-block">No. Membresía: {props?.item?.externalId}</label>  {/*externalId*/}
                        <label className="card-title text-secondary d-block">Vigencia: {moment(props?.item?.endDate, "YYYY-MM-DD").format("DD/MM/YYYY")}</label>
                    </div>
                </div>
            </Card.Body>
        </Card>
        
    )

}

export default CardMembresias