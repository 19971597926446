import React, {useState} from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import "../../styles/container.css";
import CardMembresias from "./CardMembresias";
import CardAdd from "./CardAdd";
import {useHistory} from "react-router-dom"


const ListMemberships = (props) => {

    const history = useHistory()
    const [item, setItem] = useState(null);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onClickCardAdd =() => {
        props?.onClickCardAdd()
    }

    const onClickCardDelete =(item) => {
        setItem(item)
        handleShow()
    }

    const deleteCard =() => {
        handleClose()
        props?.deleteMemberships(item)
        setItem(item)
    }

    const selectMembresia =(item) => {
        localStorage.setItem("selectedPatient", JSON.stringify(item))
        history.push({
            pathname: '/menu',
            state: {patient: item}
        })
    }

  return (
      <>
          <Row className="mb-5">
              <Col>
                  <h5 className="text-primary text-center">
                      ¡Bienvenido!
                  </h5>
                  <h6 className="color-bluerey title-membresia text-center">
                      Elige tu membresía con la que deseas utilizar tus asistencias
                  </h6>
              </Col>
          </Row>
          <Row className="mb-2">
              <Col xs="12" md={{span: 10, offset: 1}}>
                  <Row>
                    {
                            props?.memberships?.map((it, i) => (
                                <Col key={i} xs="12" md="6" className="order-md-1 order-2">
                                    <CardMembresias item={it} onClickCardDelete={onClickCardDelete} selectMembresia={selectMembresia}/>
                                </Col>
                            ))
                        }

                        <Col xs="12" md="6" className="order-md-2 order-1">
                            <CardAdd onClickCard={onClickCardAdd} />
                        </Col>
                  </Row>
              </Col>
               
          </Row>

          <Modal  {...props}
                   size="lg"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered show={show} onHide={handleClose}>
              <Modal.Body>¿Estas seguro en eliminar la membresía seleccionada de tu dispositivo?</Modal.Body>
              <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                      No
                  </Button>
                  <Button variant="primary" onClick={deleteCard}>
                      Si
                  </Button>
              </Modal.Footer>
          </Modal>
      </>
  );
};

export default ListMemberships;
