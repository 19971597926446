import React from "react";
import {Col, Row} from "react-bootstrap";
import Farmacias from "./Farmacias";
import Hospitales from "./Hospitales";
import Laboratorios from "./Laboratorios";
import Opticas from "./Opticas";

const DiscountPeoples = () => {

    return (
        <>
            <Row>
                <Col>
                    <Laboratorios />
                    <Hospitales />
                    <Farmacias />
                    <Opticas />
                </Col>
            </Row>
        </>
    )
};

export default DiscountPeoples;
